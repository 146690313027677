import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import ResourceLibrary from '../components/ResourceLibrary';
import pdfImg from '../assets/badges/resources-pdf-icon.png';
import PageIntroduction from '../components/PageIntroduction';
import { H3, Body } from '../util/typography';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) | Health Care Professional Resources`,
    keywords: `professional resources, keytruda and lenvima`,
    description: `Health care professionals can view and download resources for KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib). Download brochures and guides here.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/professional-resources/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/professional-resources/","@type":"MedicalWebPage","name":"Advanced Renal Cell Carcinoma (RCC) Brochure","description":"See the efficacy and safety data for KEYTRUDA + LENVIMA in advanced RCC."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/professional-resources/","@type":"MedicalWebPage","name":"Adverse Reaction Management Guide","description":"Learn how to help manage adverse reactions in patients treated with KEYTRUDA + LENVIMA."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/professional-resources/","@type":"MedicalWebPage","name":"Dosage and Administration Guide","description":"Learn about the dosing and administration of KEYTRUDA + LENVIMA."}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const Resources = ({location}) => {
    return (
        <DefaultLayout location={ location } name='HCP Resources' pageMeta={pageMeta} jobCode={jobCode}>
        <PageIntroduction>
                { /*<H3>Resources for health care professionals (HCPs)</H3> */}
            </PageIntroduction>
            <ResourceLibrary>
                <div src={pdfImg} title='Advanced Renal Cell Carcinoma (RCC) Brochure' href='/static/pdfs/key-len-rcc-efficacy.pdf' alt=''>
                    <Body>See the efficacy and safety data for KEYTRUDA + LENVIMA in advanced RCC.</Body>
                </div>
                <div src={pdfImg} title='Adverse Reaction Management Guide' href='/static/pdfs/adverse_reaction_management_brochure.pdf'>
                    <Body>Learn how to help manage adverse reactions in patients treated with KEYTRUDA + LENVIMA.</Body>
                </div>
                <div src={pdfImg} title='Dosage and Administration Guide' href='/static/pdfs/dosing-admin-guide.pdf' alt=''>
                    <Body>Learn about the dosing and administration of KEYTRUDA + LENVIMA.</Body>
                </div>
            </ResourceLibrary>
        </DefaultLayout>
    )
};

export default Resources;
